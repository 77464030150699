import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    justifyContent: "center",

    "& iframe": {
      width: "55.55%",
      height: 400,
      border: 0,

      "@media(min-width: 1440px)": {
        height: 450,
      },
      "@media(max-width: 767px)": {
        width: "100%",
        height: 240,
        padding: "0 6.03vw",
      },
    },
  },
}));

const Video = ({ slice }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <iframe
        width="100%"
        height="100%"
        src={slice.primary.video_link}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Video;
