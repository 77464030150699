import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { navigate } from 'gatsby';
import IconPerson from '../../images/svg/icon_person.svg';
import IconPool from '../../images/svg/icon_pool.svg';
import IconCheck from '../../images/svg/icon_round_check.svg';
import IconTime from '../../images/svg/icon_time.svg';
import { getPoolSize, getPoolTime } from './utils/pool';
import Addresses from './utils/addresses';

const useStyle = makeStyles((theme) => ({
   infoRoot: {
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F6FF',
      gap: 'auto',
      boxSizing: 'space-between',
      justifyContent: 'space-between',
      padding: '1.388vw',
      height: '24.86vw',
      '@media(min-width: 1440px)': {
         padding: 20,
         height: 358,
      },
      '@media(max-width: 767px)': {
         padding: '4.83vw',
         height: '73.429vw',
      },
   },
   infoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F6FF',
      gap: '1.388vw',
      '@media(min-width: 1440px)': {
         gap: 20,
      },
      '@media(max-width: 767px)': {
         gap: '4.83vw',
      },
   },
   memText: {
      color: theme.palette.color.blue,
      fontWeight: 700,
      lineHeight: '120%',
      fontSize: '1.944vw',
      '@media(min-width: 1440px)': {
         fontSize: 28,
      },
      '@media(max-width: 767px)': {
         fontSize: '5.797vw',
      },
   },
   button: {
      width: 'fit-content',
      fontWeight: 700,
      background: theme.palette.color.orange,
      borderRadius: 47,
      lineHeight: '128.2%',
      fontSize: '1.388vw',
      padding: '1.041vw 5.555vw',
      '@media(min-width: 1440px)': {
         fontSize: 24,
         padding: '15px 80px',
      },
      '@media(max-width: 767px)': {
         fontSize: '4.83vw',
         padding: '2.415vw 10.869vw',
      },
      '& span': {
         color: theme.palette.color.white,
      },
   },
   inlineBlockWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: '0.347vw',
      '@media(min-width: 1440px)': {
         gap: 5,
      },
      '@media(max-width: 767px)': {
         gap: '1.207vw',
      },
   },
   inlineBlock: {
      display: 'flex',
      width: '100%',
   },
   inlineBlockTitle: {
      color: theme.palette.color.blue,
      fontWeight: 450,
      lineHeight: '128.2%',
      fontSize: '1.25vw',
      '@media(min-width: 1440px)': {
         fontSize: 18,
      },
      '@media(max-width: 767px)': {
         fontSize: '3.381vw',
      },
      '& span': {
         color: theme.palette.color.main,
         opacity: 0.7,
         fontWeight: 400,
      },
   },
   inlineBlockText: {
      lineHeight: '128.2%',
      fontSize: '1.25vw',
      '@media(min-width: 1440px)': {
         fontSize: 18,
      },
      '@media(max-width: 767px)': {
         fontSize: '3.381vw',
      },
   },
   icon: {
      width: '1.805vw',
      height: '1.805vw',
      marginRight: '0.694vw',
      '@media(min-width: 1440px)': {
         width: 26,
         height: 26,
         marginRight: 10,
      },
      '@media(max-width: 767px)': {
         width: '4.83vw',
         height: '4.83vw',
         marginRight: '2.415vw',
      },
   },
   methodicWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.347vw',
      '@media(min-width: 1440px)': {
         gap: 5,
      },
      '@media(max-width: 767px)': {
         gap: '1.207vw',
      },
   },
   methodicBlock: {
      display: 'flex',
      flexDirection: 'row',
   },
   methodicText: {
      flex: 1,
      fontWeight: 400,
      lineHeight: '128.2%',
      color: 'rgba(7, 15, 48, 0.7)',
      fontSize: '1.25vw',
      '@media(min-width: 1440px)': {
         fontSize: 18,
      },
      '@media(max-width: 767px)': {
         fontSize: '3.381vw',
      },
   },
}));

export default function CardInfo({ membership, type }) {
   const classes = useStyle();
   const PoolInfo = () => {
      return (
         <>
            <div className={classes.inlineBlockWrapper}>
               <div className={classes.inlineBlock}>
                  <IconPool className={classes.icon} />
                  <Typography className={classes.inlineBlockTitle}>
                     <span>Бассейн: </span>
                     {getPoolSize(membership)}
                  </Typography>
               </div>
               <div className={classes.inlineBlock}>
                  <IconTime className={classes.icon} />
                  <Typography className={classes.inlineBlockTitle}>
                     <span>Время: </span>
                     {getPoolTime(membership)}
                  </Typography>
               </div>
            </div>
            <Addresses addresses={membership.addresses} />
         </>
      );
   };

   const FitnessInfo = () => {
      return (
         <>
            <div className={classes.inlineBlockWrapper}>
               <div className={classes.inlineBlock}>
                  <IconPerson className={classes.icon} />
                  <Typography className={classes.inlineBlockTitle}>
                     <span>Проводится: </span>
                     {membership.trained_by}
                  </Typography>
               </div>
               <div className={classes.inlineBlock}>
                  <IconTime className={classes.icon} />
                  <Typography className={classes.inlineBlockTitle}>
                     <span>Время: </span>
                     {membership.time}
                  </Typography>
               </div>
            </div>
            <Addresses addresses={membership.addresses} />
         </>
      );
   };

   const MethodicInfo = () => {
      return (
         <div className={classes.methodicWrapper}>
            {membership.benefits.map((item) => (
               <div className={classes.methodicBlock}>
                  <IconCheck className={classes.icon} />
                  <Typography className={classes.methodicText}>{item.benefit_short}</Typography>
               </div>
            ))}
         </div>
      );
   };

   const getInfo = () => {
      if (type === 'pool') return <PoolInfo membership={membership} />;
      if (Object.hasOwn(membership, 'benefits') && !Object.hasOwn(membership, 'addresses')) return <MethodicInfo membership={membership} />;
      return <FitnessInfo membership={membership} />;
   };

   return (
      <div className={classes.infoRoot}>
         <div className={classes.infoWrapper}>
            <Typography className={classes.memText}>{membership.name}</Typography>
            {getInfo()}
         </div>
         <Button
            className={classes.button}
            onClick={(e) => navigate(`/memberships/${membership.uid}`)}
         >
            Подробнее
         </Button>
      </div>
   );
}
