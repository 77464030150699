import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Button } from '@material-ui/core';
import BlockHeaderText from '../../newsPage/blockHeaderText';
import { makeStyles } from '@material-ui/core';
import MembershipList from '../../membership/membershipList';

const useStyle = makeStyles((theme) => ({
   categoryBox: {
      display: 'flex',
      flexDirection: 'row',
      margin: '4.166vw 3.47vw 0 3.47vw',
      gap: '2.083vw',
      '@media(min-width: 1440px)': {
         margin: '60px 50px 0px 50px',
         gap: 30,
      },
      '@media(max-width: 767px)': {
         margin: '4.83vw 12.077vw 4.83vw 6.038vw',
         gap: '4.83vw',
      },
   },

   categoryButton: {
      borderRadius: 0,
      fontWeight: 700,
      lineHeight: 1.2,
      opacity: 0.5,
      padding: '0.694vw 0 0.486vw',
      fontSize: '1.66vw',
      borderBottom: '3px transparent solid',
      '@media(min-width: 1440px)': {
         padding: '10px 0 7px',
         fontSize: 24,
      },
      '@media(max-width: 767px)': {
         borderBottom: '1.5px transparent solid',
         padding: '2.415vw 0 2.053vw',
         fontSize: '2.898vw',
      },
      '& span': {
         color: theme.palette.color.darkBlue,
      },
   },
   poolActive: {
      opacity: 1,
      borderBottom: '3px #0047FF solid',
      '@media(max-width: 767px)': {
         borderBottom: '1.5px #0047FF solid',
      },
   },
   fitnessActive: {
      opacity: 1,
      borderBottom: '3px #FF3E00 solid',
      '@media(max-width: 767px)': {
         borderBottom: '1.5px #FF3E00 solid',
      },
   },
}));

/**
 * Компонент Абонементов
 * @module src/components/memberships
 */

export default function Memberships({ items }) {
   const data = useStaticQuery(graphql`
      {
         allPrismicMembershipFitness {
            edges {
               node {
                  uid
                  data {
                     name
                     price
                     tag_age
                     tag_category
                     tag_type
                     time
                     trained_by
                     addresses {
                        address {
                           document {
                              ... on PrismicAddress {
                                 id
                                 data {
                                    name
                                 }
                              }
                           }
                        }
                     }
                     images {
                        image {
                           gatsbyImageData
                        }
                     }
                  }
               }
            }
         }
         allPrismicMembershipMethodic {
            edges {
               node {
                  uid
                  data {
                     name
                     price
                     tag_age
                     tag_category
                     tag_type
                     benefits {
                        benefit
                        benefit_short
                     }
                     images {
                        image {
                           gatsbyImageData
                        }
                     }
                  }
               }
            }
         }
         allPrismicMembershipPool {
            edges {
               node {
                  uid
                  data {
                     expiration_time
                     gym_time
                     name
                     pool_size_25
                     pool_size_50
                     pool_size_padding
                     pool_time
                     tag_age
                     tag_category
                     tag_type
                     lessons_cost {
                        price
                        weekly_count
                     }
                     images {
                        image {
                           gatsbyImageData
                        }
                     }
                     addresses {
                        address {
                           document {
                              ... on PrismicAddress {
                                 data {
                                    name
                                 }
                              }
                           }
                        }
                     }
                  }
               }
            }
         }
      }
   `);
   const classes = useStyle();

   const poolAbonement = [];
   const methodicAbonement = [];
   const fitnessAbonement = [];

   items.forEach((item) => {
      if (item.abonements_relations.type === 'membership_pool' && item.abonements_relations.uid !== null) {
         poolAbonement.push(item.abonements_relations.uid);
      }
      if (item.abonements_relations.type === 'membership_methodic' && item.abonements_relations.uid !== null) {
         methodicAbonement.push(item.abonements_relations.uid);
      }
      if (item.abonements_relations.type === 'membership_fitness' && item.abonements_relations.uid !== null) {
         fitnessAbonement.push(item.abonements_relations.uid);
      }
   });

   const allPoolMemberships = data.allPrismicMembershipPool.edges.map((item) => {
      return { ...item.node.data, uid: item.node.uid };
   });
   const allMethodic = data.allPrismicMembershipMethodic.edges.map((item) => {
      return { ...item.node.data, uid: item.node.uid };
   });
   const allFitness = data.allPrismicMembershipFitness.edges.map((item) => {
      return { ...item.node.data, uid: item.node.uid };
   });

   const filteredPoolMemberships = allPoolMemberships.filter((item) => poolAbonement.includes(item.uid));
   const filteredMethodic = allMethodic.filter((item) => methodicAbonement.includes(item.uid));
   const filteredFitness = allFitness.filter((item) => fitnessAbonement.includes(item.uid));

   const allFitnessMemberships = [...filteredMethodic, ...filteredFitness];
   const [activeFitness, setActiveFitness] = React.useState(filteredPoolMemberships.length > 0 ? false : true);

   return (
      <>
         <BlockHeaderText
            text='Абонементы'
            styles={{ height: 50 }}
         />
         <div className={classes.categoryBox}>
            {filteredPoolMemberships?.length > 0 && (
               <Button
                  className={classes.categoryButton + ' ' + (activeFitness ? '' : classes.poolActive)}
                  onClick={(e) => setActiveFitness(false)}
               >
                  Спортивный клуб по плаванию
               </Button>
            )}
            {allFitnessMemberships?.length > 0 && (
               <Button
                  className={classes.categoryButton + ' ' + (activeFitness ? classes.fitnessActive : '')}
                  onClick={(e) => setActiveFitness(true)}
               >
                  Фитнес-клуб
               </Button>
            )}
         </div>
         {activeFitness ? (
            <MembershipList
               array={allFitnessMemberships}
               type={'fitness'}
            />
         ) : (
            <MembershipList
               array={filteredPoolMemberships}
               type={'pool'}
            />
         )}
      </>
   );
}
