import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import CardInfo from './cardInfo';

const useStyle = makeStyles((theme) => ({
   rootCard: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
   },

   imageWrapper: {
      display: 'flex',
      position: 'relative',
      alignContent: 'flex-end',
      justifyItems: 'flex-start',
      width: '100%',
      height: '20.138vw',
      '@media(min-width: 1440px)': {
         height: 290,
      },
      '@media(max-width: 767px)': {
         height: '60.386vw',
      },
   },
   gatsbyImage: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
   },
   dummyDiv: {
      width: '100%',
      height: '100%',
   },
   blueBackground: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      right: 0,
      zIndex: 2,
      background: 'linear-gradient(180deg, rgba(28, 70, 246, 0) 41%, #1C46F6 78%)',
   },
   tagWrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      zIndex: 3,
      gap: '1.041vw',
      padding: '1.388vw',
      '@media(min-width: 1440px)': {
         padding: 20,
         gap: 15,
      },
      '@media(max-width: 767px)': {
         padding: '6.038vw 4.83vw',
         gap: '2.415vw',
      },
   },
   topTags: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: '1.041vw',
      '@media(min-width: 1440px)': {
         gap: 15,
      },
      '@media(max-width: 767px)': {
         gap: '2.415vw',
      },
   },
   tag: {
      padding: '0.486vw 0.694vw',
      '@media(min-width: 1440px)': {
         padding: '7px 10px',
      },
      '@media(max-width: 767px)': {
         padding: '1.207vw 2.415vw',
      },
   },
   tagText: {
      fontWeight: 700,
      color: theme.palette.color.white,
      lineHeight: '128.2%',
      fontSize: '1.666vw',
      '@media(min-width: 1440px)': {
         fontSize: 24,
      },
      '@media(max-width: 767px)': {
         fontSize: '4.347vw',
      },
   },
}));

export default function MembershipCard({ membership, type }) {
   const classes = useStyle();
   const getPrice = () => {
      switch (type) {
         case 'pool':
            return membership?.lessons_cost[0].price;
         default:
            return membership.price;
      }
   };
   const price = getPrice();

   return (
      <div className={classes.rootCard}>
         <div className={classes.imageWrapper}>
            <GatsbyImage
               className={classes.gatsbyImage}
               image={membership.images[0].image.gatsbyImageData}
               objectFit='cover'
            />
            <div className={classes.dummyDiv} />
            <div className={classes.blueBackground} />
            <div className={classes.tagWrapper}>
               <div className={classes.topTags}>
                  <div
                     className={classes.tag}
                     style={{ background: '#FC8F9A' }}
                  >
                     <Typography className={classes.tagText}>{membership.tag_category}</Typography>
                  </div>
                  <div
                     className={classes.tag}
                     style={{ background: '#FF9E45' }}
                  >
                     <Typography className={classes.tagText}>{membership.tag_age}</Typography>
                  </div>
               </div>
               <div
                  className={classes.tag}
                  style={{ background: '#00DF8F', width: 'fit-content' }}
               >
                  <Typography className={classes.tagText}>от {price} ₽</Typography>
               </div>
            </div>
         </div>
         <CardInfo
            membership={membership}
            type={type}
         />
      </div>
   );
}
