import React from "react";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  addressesWrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "0.694vw",
    "@media(min-width: 1440px)": {
      gap: 10,
    },
    "@media(max-width: 767px)": {
      gap: "2.415vw",
    },
  },
  address: {
    width: "fit-content",
    background: "rgba(27, 70, 245, 0.09)",
    borderRadius: 5,
    padding: "0.347vw 0.555vw",
    "@media(min-width: 1440px)": {
      padding: "5px 8px",
    },
    "@media(max-width: 767px)": {
      padding: "1.207vw 1.932vw",
    },
  },
  addressText: {
    fontWeight: 400,
    color: theme.palette.color.blue,
    lineHeight: "128.2%",
    fontSize: "1.111vw",
    "@media(min-width: 1440px)": {
      fontSize: 16,
    },
    "@media(max-width: 767px)": {
      fontSize: "2.898vw",
    },
  },

  addressTextBig: {
    fontSize: "1.25vw",
    "@media(min-width: 1440px)": {
      fontSize: 18,
    },
    "@media(max-width: 767px)": {
      fontSize: "2.898vw",
    },
  },
}));

export default function Addresses({ addresses, pageType = "card" }) {
  const classes = useStyle();
  return (
    <div className={classes.addressesWrapper}>
      {addresses.map((item) => {
        let address = item.address.document.data.name;
        return (
          <div className={classes.address} key={`${address}`}>
            <Typography
              className={
                classes.addressText +
                " " +
                (pageType === "template" ? classes.addressTextBig : "")
              }
            >
              {address}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
