import React from "react";

/**
 * Динамический SVG для блока серой панели
 * @module src/components/constructor/grayPanelWithBlocks/HeaderSingleBlock/wavesSVG.js
 * @param {Object} props - объект свойств компонента React
 * @param {String} props.color - цвет SVG
 */
export default function WavesSVG({ color }) {
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 62 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="clip0">
          <path
            d="M116.697 17.3628C110.678 17.3628 107.55 15.0598 104.793 13.0277C102.235 11.1438 100.029 9.51582 95.3978 9.51582C90.767 9.51582 88.5609 11.1438 86.003 13.0277C83.2453 15.0598 80.1174 17.3628 74.0985 17.3628C68.0797 17.3628 64.9518 15.0598 62.194 13.0277C59.6362 11.1438 57.43 9.51582 52.7993 9.51582C48.1685 9.51582 45.9623 11.1438 43.4045 13.0277C40.6468 15.0598 37.5189 17.3628 31.5 17.3628C25.4811 17.3628 22.3532 15.0598 19.5918 13.0277C17.034 11.1438 14.8278 9.51582 10.197 9.51582C5.56629 9.51582 3.36011 11.1438 0.802269 13.0277C-1.95545 15.0598 -5.08334 17.3628 -11.1022 17.3628C-17.1211 17.3628 -20.249 15.0598 -23.0067 13.0277C-25.5645 11.1438 -27.7707 9.51582 -32.4015 9.51582C-37.0322 9.51582 -39.2384 11.1438 -41.7963 13.0277C-44.554 15.0598 -47.6819 17.3628 -53.7007 17.3628C-59.7196 17.3628 -62.8475 15.0598 -65.6052 13.0277C-68.1631 11.1438 -70.3692 9.51953 -75 9.51953V5.27344C-68.9811 5.27344 -65.8532 7.57634 -63.0955 9.60853C-60.5377 11.4924 -58.3315 13.1204 -53.7007 13.1204C-49.07 13.1204 -46.8638 11.4924 -44.306 9.60853C-41.5482 7.57634 -38.4203 5.27344 -32.4015 5.27344C-26.3826 5.27344 -23.2547 7.57634 -20.497 9.60853C-17.9392 11.4924 -15.733 13.1204 -11.1022 13.1204C-6.47146 13.1204 -4.26528 11.4924 -1.70744 9.60853C1.05028 7.57634 4.17817 5.27344 10.197 5.27344C16.2159 5.27344 19.3438 7.57634 22.1015 9.60853C24.6594 11.4924 26.8656 13.1204 31.4963 13.1204C36.1271 13.1204 38.3332 11.4924 40.8911 9.60853C43.6525 7.57634 46.7767 5.27344 52.7993 5.27344C58.8218 5.27344 61.946 7.57634 64.7038 9.60853C67.2616 11.4924 69.4678 13.1204 74.0985 13.1204C78.7293 13.1204 80.9355 11.4924 83.4933 9.60853C86.251 7.57634 89.3789 5.27344 95.3978 5.27344C101.417 5.27344 104.545 7.57634 107.302 9.60853C109.86 11.4924 112.066 13.1204 116.697 13.1204C121.328 13.1204 123.534 11.4924 126.092 9.60853C128.853 7.57634 131.977 5.27344 138 5.27344V9.51953C133.369 9.51953 131.163 11.1475 128.605 13.0314C125.844 15.0598 122.72 17.3628 116.697 17.3628Z"
            fill={color}
          />
          <path
            d="M116.697 36.8821C110.678 36.8821 107.55 34.5792 104.793 32.547C102.235 30.6631 100.029 29.0352 95.3978 29.0352C90.767 29.0352 88.5609 30.6631 86.003 32.547C83.2453 34.5792 80.1174 36.8821 74.0985 36.8821C68.0797 36.8821 64.9518 34.5792 62.194 32.547C59.6362 30.6631 57.43 29.0352 52.7993 29.0352C48.1685 29.0352 45.9623 30.6631 43.4045 32.547C40.6468 34.5792 37.5189 36.8821 31.5 36.8821C25.4811 36.8821 22.3532 34.5792 19.5918 32.547C17.034 30.6631 14.8278 29.0352 10.197 29.0352C5.56629 29.0352 3.36011 30.6631 0.802269 32.547C-1.95545 34.5792 -5.08334 36.8821 -11.1022 36.8821C-17.1211 36.8821 -20.249 34.5792 -23.0067 32.547C-25.5645 30.6631 -27.7707 29.0352 -32.4015 29.0352C-37.0322 29.0352 -39.2384 30.6631 -41.7963 32.547C-44.554 34.5792 -47.6819 36.8821 -53.7007 36.8821C-59.7196 36.8821 -62.8475 34.5792 -65.6052 32.547C-68.1631 30.6631 -70.3692 29.0352 -75 29.0352V24.7891C-68.9811 24.7891 -65.8532 27.092 -63.0955 29.1242C-60.5377 31.008 -58.3315 32.6323 -53.7007 32.6323C-49.07 32.6323 -46.8638 31.0043 -44.306 29.1242C-41.5482 27.092 -38.4203 24.7891 -32.4015 24.7891C-26.3826 24.7891 -23.2547 27.092 -20.497 29.1242C-17.9392 31.008 -15.733 32.6323 -11.1022 32.6323C-6.47146 32.6323 -4.26528 31.0043 -1.70744 29.1242C1.05028 27.092 4.17817 24.7891 10.197 24.7891C16.2159 24.7891 19.3438 27.092 22.1015 29.1242C24.6594 31.008 26.8656 32.6323 31.4963 32.6323C36.1271 32.6323 38.3332 31.0043 40.8911 29.1242C43.6488 27.092 46.7767 24.7891 52.7956 24.7891C58.8144 24.7891 61.9423 27.092 64.7001 29.1242C67.2579 31.008 69.4641 32.6323 74.0948 32.6323C78.7256 32.6323 80.9318 31.0043 83.4896 29.1242C86.2473 27.092 89.3752 24.7891 95.3941 24.7891C101.413 24.7891 104.541 27.092 107.299 29.1242C109.856 31.008 112.063 32.6323 116.693 32.6323C121.324 32.6323 123.53 31.0043 126.088 29.1242C128.846 27.092 131.974 24.7891 137.993 24.7891V29.0352C133.362 29.0352 131.156 30.6631 128.598 32.547C125.844 34.5792 122.72 36.8821 116.697 36.8821Z"
            fill={color}
          />
          <path
            d="M116.697 56.3938C110.678 56.3938 107.55 54.0909 104.793 52.0587C102.235 50.1749 100.029 48.5469 95.398 48.5469C90.7672 48.5469 88.5611 50.1749 86.0032 52.0587C83.2455 54.0909 80.1176 56.3938 74.0987 56.3938C68.0799 56.3938 64.952 54.0909 62.1942 52.0587C59.6364 50.1749 57.4302 48.5469 52.7995 48.5469C48.1687 48.5469 45.9625 50.1749 43.4047 52.0587C40.647 54.0909 37.5191 56.3938 31.5002 56.3938C25.4813 56.3938 22.3535 54.0909 19.5957 52.0587C17.0379 50.1749 14.8317 48.5469 10.201 48.5469C5.5702 48.5469 3.36401 50.1749 0.806175 52.0587C-1.95155 54.0909 -5.07943 56.3938 -11.0983 56.3938C-17.1172 56.3938 -20.2451 54.0909 -23.0028 52.0587C-25.5606 50.1749 -27.7668 48.5469 -32.3976 48.5469C-37.0283 48.5469 -39.2345 50.1749 -41.7923 52.0587C-44.5501 54.0909 -47.678 56.3938 -53.6968 56.3938C-59.7157 56.3938 -62.8436 54.0909 -65.6013 52.0587C-68.1592 50.1749 -70.3653 48.5469 -74.9961 48.5469V44.3008C-68.9772 44.3008 -65.8493 46.6037 -63.0916 48.6359C-60.5338 50.5197 -58.3276 52.1477 -53.6968 52.1477C-49.0661 52.1477 -46.8599 50.5197 -44.3021 48.6359C-41.5443 46.6037 -38.4164 44.3008 -32.3976 44.3008C-26.3787 44.3008 -23.2508 46.6037 -20.4931 48.6359C-17.9352 50.5197 -15.7291 52.1477 -11.0983 52.1477C-6.46755 52.1477 -4.26138 50.5197 -1.70354 48.6359C1.05418 46.6037 4.18208 44.3008 10.201 44.3008C16.2198 44.3008 19.3477 46.6037 22.1054 48.6359C24.6633 50.5197 26.8694 52.1477 31.5002 52.1477C36.131 52.1477 38.3371 50.5197 40.895 48.6359C43.6527 46.6037 46.7806 44.3008 52.7995 44.3008C58.8183 44.3008 61.9462 46.6037 64.704 48.6359C67.2618 50.5197 69.468 52.1477 74.0987 52.1477C78.7295 52.1477 80.9357 50.5197 83.4935 48.6359C86.2512 46.6037 89.3791 44.3008 95.398 44.3008C101.417 44.3008 104.545 46.6037 107.302 48.6359C109.86 50.5197 112.066 52.1477 116.697 52.1477C121.328 52.1477 123.534 50.5197 126.092 48.6359C128.85 46.6037 131.978 44.3008 137.997 44.3008V48.5469C133.366 48.5469 131.16 50.1749 128.602 52.0587C125.844 54.0909 122.72 56.3938 116.697 56.3938Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect y="0.273438" width="62" height="62" rx="31" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
