import React from "react";

export const getPoolSize = (membership) => {
    let sizeArr = [];
    if (membership.pool_size_25) sizeArr.push("25м");
    if (membership.pool_size_50) sizeArr.push("50м");
    if (membership.pool_size_padding) sizeArr.push("лягушатник");
    return sizeArr.join(", ");
  };

  export const getPoolTime = (membership) => {
    return (
      <>
        {membership?.gym_time ? (
          <>
            <span>зал - </span>
            {membership.gym_time}
          </>
        ) : null}
        {membership?.gym_time && membership?.pool_time ? <span>, </span> : null}
        {membership?.pool_time ? (
          <>
            <span>вода - </span>
            {membership.pool_time}
          </>
        ) : null}
      </>
    );
  };