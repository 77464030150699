import React from "react"
import SliderRegular from "../slider";
import {makeStyles} from "@material-ui/core";
import {GatsbyImage} from "gatsby-plugin-image";
import BlockHeaderText from "../../blockHeaderText";

const useStyles = makeStyles((theme) => ({
    item: {
        "@media (max-width: 768px)": {
            margin: "51px 14.5% 0 14.5%",
            maxWidth: "35%",
        },

        "@media (min-width: 768px)": {
            margin: "51px 5% 0 5%",
            maxWidth: "16%",
        },
        "&:first-of-type": {
            marginLeft: 0
        },
        "&:last-of-type": {
            marginRight: 0,
        },

        "& > * ": {
            width: "100%"
        },

        "& a:hover":{
            cursor: "pointer"
        },
    },

    logoGrid: {
        "@media (min-width: 768px)": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10vw",
            padding: "0 50px",
            "& > *": {
                maxWidth: 220
            }
        },

        "& a:hover":{
          cursor: "pointer"
        },

        "@media (max-width: 767px)": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "15%",
            rowGap: 40,
            padding: "0 25px"
        }
    }
}))

const SliderLogo = ({slice}) => {

    const classes = useStyles()
    const handleClick = (url) => {
        if (url)
            window.open(url, '_blank').focus()
    }

    return (
        <>
            <BlockHeaderText text="Наши партнеры"/>
            {slice.items.length > 4 ?
                <SliderRegular mobileScrollBar={true} padding={true} hideNav={false}>
                    {slice.items.map(item =>
                        <a onClick={()=>handleClick(item["website_link"].url)}>
                            <GatsbyImage
                                image={item.image?.localFile?.childImageSharp?.gatsbyImageData}
                                alt={item.image.alt ?? "photo"}
                                className={classes.item}
                                onClick={()=>handleClick(item["website_link"].url)}
                            />
                        </a>
                    )}
                </SliderRegular>
                :
                <div className={classes.logoGrid}>
                    {slice.items.map(item =>
                        <a onClick={()=>handleClick(item["website_link"].url)}>
                            <GatsbyImage
                                image={item.image?.localFile?.childImageSharp?.gatsbyImageData}
                                alt={item.image.alt ?? "photo"}/>
                        </a>
                    )}
                </div>
            }
        </>
    )
}

export default SliderLogo
