import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import MembershipCard from './membershipCard';

const useStyle = makeStyles((theme) => ({
   gridContainer: {
      marginTop: 0,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      padding: '4.166vw 3.47vw 0',
      columnGap: '3.298vw',
      rowGap: '3.472vw',
      '@media(min-width: 1440px)': {
         padding: '60px 50px 0',
         columnGap: 47.5,
         rowGap: 50,
      },
      '@media(max-width: 767px)': {
         gridTemplateColumns: '1fr',
         padding: '0 6.038vw',
         rowGap: '5.797vw',
      },
   },
}));

export default function MembershipList({ array, type }) {
   const classes = useStyle();
   return (
      <Grid
         container
         className={classes.gridContainer}
      >
         {array.map((item, idx) => (
            <MembershipCard
               key={`membership-${idx}`}
               membership={item}
               type={type}
            />
         ))}
      </Grid>
   );
}
